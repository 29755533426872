
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getModule } from "vuex-module-decorators";
import { AuthModule } from "@/store/modules";

interface PasswordConfirm {
  password: string;
  password2: string;
}

export default defineComponent({
  name: "password-confirmation",
  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const token = route.params.token;

    const authModule = getModule(AuthModule, store);

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const passwordConfirm = Yup.object().shape({
      password: Yup.string()
        .required()
        .label(i18n("auth.signin.passwordLabel"))
        .matches(
          /^(?=.*[\\w)(?=.*[0-9])(?=.{8,})/,
          i18n("auth.signin.passwordlength")
        ),
      password2: Yup.string()
        .required()
        .label(i18n("auth.confirmationPassword.password2Label"))
        .matches(
          /^(?=.*[\\w)(?=.*[0-9])(?=.{8,})/,
          i18n("auth.signin.passwordlength")
        )
        .oneOf(
          [Yup.ref("password"), null],
          i18n("auth.confirmationPassword.differentPasswords")
        ),
    });

    useForm<PasswordConfirm>({
      validationSchema: passwordConfirm,
    });

    const { value: password } = useField("password");
    const { value: password2 } = useField("password2");

    //Form submit function
    const onSubmitLogin = () => {
      // Clear existing errors
      authModule.logOut();

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request VER...................
      //{"resetKey": "KEY_PARA_RESETEAR","password": "NUEVA_PASS","confirmPassword": "NUEVA_PASS"}
      let payload = {
        resetKey: token,
        password: password.value,
        confirmPassword: password2.value,
      };

      authModule
        .changePassword(payload)
        .then(() => {
          // Ir a la pagina de login si cambia con exito la contraseña
          router.push({ name: "sign-in" });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          // Alert then changePassword failed
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitLogin,
      passwordConfirm,
      submitButton,
      password,
      password2,
    };
  },
});
